import React from 'react';

function Lists({ fetchedData }) {
  return (
    <div>
      Lists
    </div>
  );
}

export default Lists;
